import Mapper from './Mapper';
import { CreatePlatformOperatorRequestDTO, PlatformOperatorResponseDTO } from 'typings/dto/platformOperator';

export default class PlatformOperatorMapper extends Mapper<
  PlatformOperator,
  PlatformOperatorResponseDTO,
  CreatePlatformOperatorRequestDTO
> {
  constructor() {
    super(
      {
        enterprise: 'enterprise',
        tenant: 'tenant',
        settings: 'settings',
      },
      null
    );
  }

  public responseDTOToModel = (dto: PlatformOperatorResponseDTO): PlatformOperator => {
    const outerMappers = this.getOuterMappers();
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      enterprise: outerMappers.enterprise.responseDTOToModel(dto.enterprise),
      tenant: dto.tenant,
      settings: dto.settings,
    };
  };
}
