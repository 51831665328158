import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD, PAGE_TYPE, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';

export default class EnterpriseSettingsRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root);
  public serviceProvider: EnterpriseServiceCompaniesRoutes;
  public brand: BrandRoutes;
  public legalEntity: LegalEntityRoutes;
  public department: DepartmentRoutes;
  public employee: EmployeeRoutes;
  public technician: TechnicianRoutes;
  public region: RegionRoutes;
  public technicianTeam: TechnicianTeamRoutes;
  public orderCustomField: OrderCustomFieldRoutes;
  public notificationSettings: NotificationSettingsRoutes;

  constructor() {
    super();
    this.serviceProvider = new EnterpriseServiceCompaniesRoutes(this);
    this.brand = new BrandRoutes(this);
    this.legalEntity = new LegalEntityRoutes(this);
    this.department = new DepartmentRoutes(this);
    this.employee = new EmployeeRoutes(this);
    this.technician = new TechnicianRoutes(this);
    this.region = new RegionRoutes(this);
    this.technicianTeam = new TechnicianTeamRoutes(this);
    this.orderCustomField = new OrderCustomFieldRoutes(this);
    this.notificationSettings = new NotificationSettingsRoutes(this);
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.enterprise> = (_p, permissions, translate) => {
    return permissions.can(MODEL_PERMISSION.CAN_READ_ENTERPRISE_LIST)
      ? [{ name: translate('menus.main.enterprises'), href: this.getListPath() }]
      : [];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.enterpriseName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, PAGE_TYPE.new, PAGE_TYPE.add);

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PAGE_TYPE.edit);

  getOrderSettingsInfoBc = this.getInstanceBc;
  getOrderSettingsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderSettings,
      PAGE_TYPE.info
    );

  getOrderSettingsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInstanceChildBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getOrderSettingsInfoPath(p));
    return breadcrumbs;
  };

  getOrderSettingsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderSettings,
      PAGE_TYPE.edit
    );

  getPOSettingsInfoBc = this.getInstanceBc;
  getPOSettingsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.poSettings, PAGE_TYPE.info);

  getPOSettingsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.getInstanceChildBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getPOSettingsInfoPath(p));
    return breadcrumbs;
  };

  getPOSettingsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.poSettings, PAGE_TYPE.edit);

  getApiSettingsInfoBc = this.getInstanceBc;
  getApiSettingsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.apiSettings, PAGE_TYPE.info);

  getApiSettingsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInstanceChildBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getApiSettingsInfoPath(p));
    return breadcrumbs;
  };
  getApiSettingsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.apiSettings, PAGE_TYPE.edit);

  getTechnicianSettingsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInstanceChildBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.technician.getListPath(p));
    return breadcrumbs;
  };
  getTechnicianSettingsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicianSettings,
      PAGE_TYPE.edit
    );
}

class EnterpriseServiceCompaniesRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc = this.getEmptyBreadcrumbs;

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.parent.getInstanceChildBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.serviceCompanies,
      PAGE_TYPE.list
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.serviceCompanies,
      PAGE_TYPE.edit
    );
}

class BrandRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.parent.getInstanceChildBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'brandName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInstanceBc(p, pm, t, n);
    breadcrumbs.push({ name: n.brandName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.brands, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.brands,
      params.brandId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.brands,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.brands,
      params.brandId,
      PAGE_TYPE.edit
    );

  getClientsAppInfoBc = this.getInstanceBc;
  getClientsAppInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.brands,
      params.brandId,
      PATH_PARAM_DASHBOARD.brand.clientsApp,
      PAGE_TYPE.info
    );

  getClientsAppEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'brandName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getEditBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getClientsAppInfoPath(p));
    return breadcrumbs;
  };
  getClientsAppEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.brands,
      params.brandId,
      PATH_PARAM_DASHBOARD.brand.clientsApp,
      PAGE_TYPE.edit
    );

  getWidgetSettingsInfoBc = this.getInstanceBc;
  getWidgetSettingsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.brands,
      params.brandId,
      PATH_PARAM_DASHBOARD.brand.widgetSettings,
      PAGE_TYPE.info
    );

  getWidgetSettingsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'brandName'> =
    (p, pm, t, n) => {
      const breadcrumbs = this.getEditBc(p, pm, t, n);
      this.setLastBcLink(breadcrumbs, this.getWidgetSettingsInfoPath(p));
      return breadcrumbs;
    };
  getWidgetSettingsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.brands,
      params.brandId,
      PATH_PARAM_DASHBOARD.brand.widgetSettings,
      PAGE_TYPE.edit
    );
}

class LegalEntityRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.parent.getEditBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'legalEntityName'> =
    (p, pm, t, n) => {
      return this.getAddBc(p, pm, t, n).concat([{ name: n.legalEntityName, href: this.getInfoPath(p) }]);
    };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.legalEntities,
      PAGE_TYPE.list
    );

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.legalEntities,
      params.legalEntityId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.legalEntities,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.legalEntities,
      params.legalEntityId,
      PAGE_TYPE.edit
    );
}

class DepartmentRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.parent.getEditBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'departmentName'> =
    (p, pm, t, n) => {
      return this.getAddBc(p, pm, t, n).concat([{ name: n.departmentName, href: this.getInfoPath(p) }]);
    };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.departments, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.departments,
      params.departmentId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.departments,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.departments,
      params.departmentId,
      PAGE_TYPE.edit
    );
}

class EmployeeRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private enterprise: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.enterprise.getEditBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'userName'> = (
    p,
    pm,
    t,
    n
  ) => {
    return this.getAddBc(p, pm, t, n).concat([{ name: n.userName, href: this.getInfoPath(p) }]);
  };

  getListBc = this.enterprise.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.employees, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.employees,
      params.userId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.employees,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.employees,
      params.userId,
      PAGE_TYPE.edit
    );

  getNotificationsInfoBc = this.getInstanceBc;
  getNotificationsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.employees,
      params.userId,
      PATH_PARAM_DASHBOARD.user.notifications,
      PAGE_TYPE.info
    );

  getNotificationsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'userName'> = (
    p,
    pm,
    t,
    n
  ) => {
    return this.getAddBc(p, pm, t, n).concat([{ name: n.userName, href: this.getNotificationsInfoPath(p) }]);
  };
  getNotificationsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.employees,
      params.userId,
      PATH_PARAM_DASHBOARD.user.notifications,
      PAGE_TYPE.edit
    );
}

class TechnicianRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private enterprise: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.enterprise.getEditBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'userName'> = (
    p,
    pm,
    t,
    n
  ) => {
    return this.getAddBc(p, pm, t, n).concat([{ name: n.userName, href: this.getInfoPath(p) }]);
  };

  getListBc = this.enterprise.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.technicians, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PAGE_TYPE.edit
    );

  getNotificationsInfoBc = this.getInstanceBc;
  getNotificationsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PATH_PARAM_DASHBOARD.user.notifications,
      PAGE_TYPE.info
    );

  getNotificationsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'userName'> = (
    p,
    pm,
    t,
    n
  ) => {
    return this.getAddBc(p, pm, t, n).concat([{ name: n.userName, href: this.getNotificationsInfoPath(p) }]);
  };
  getNotificationsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PATH_PARAM_DASHBOARD.user.notifications,
      PAGE_TYPE.edit
    );

  getLegalEntityInfoBc = this.getInstanceBc;
  getLegalEntityInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PATH_PARAM_DASHBOARD.enterpriseEmployee.legalEntity,
      PAGE_TYPE.info
    );

  getLegalEntityEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.enterprise,
    'enterpriseName' | 'technicianName'
  > = (p, pm, t, n) => {
    return this.getInstanceBc(p, pm, t, n).concat([{ name: n.technicianName, href: this.getLegalEntityInfoPath(p) }]);
  };
  getLegalEntityEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PATH_PARAM_DASHBOARD.enterpriseEmployee.legalEntity,
      PAGE_TYPE.edit
    );

  getReviewsBc = this.getInstanceBc;
  getReviewsPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PATH_PARAM_DASHBOARD.scEmployee.reviews
    );

  getRegionServiceListBc = this.getInstanceBc;
  getRegionServiceListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PATH_PARAM_DASHBOARD.scEmployee.regionServices,
      PAGE_TYPE.list
    );

  getRegionServiceEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.enterprise,
    'enterpriseName' | 'technicianName'
  > = (p, pm, t, n) => {
    return this.getInstanceBc(p, pm, t, n).concat([{ name: n.technicianName, href: this.getRegionServiceListPath(p) }]);
  };
  getRegionServiceEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicians,
      params.technicianId,
      PATH_PARAM_DASHBOARD.scEmployee.regionServices,
      PAGE_TYPE.edit
    );
}

class RegionRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.parent.getInstanceChildBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'regionName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInstanceBc(p, pm, t, n);
    breadcrumbs.push({ name: n.regionName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.enterprise.regions, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.regions,
      params.regionId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.regions,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.regions,
      params.regionId,
      PAGE_TYPE.edit
    );
}

class TechnicianTeamRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.parent.getInstanceChildBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.enterprise,
    'enterpriseName' | 'technicianTeamName'
  > = (p, pm, t, n) => {
    const breadcrumbs = this.getInstanceBc(p, pm, t, n);
    breadcrumbs.push({ name: n.technicianTeamName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicianTeams,
      PAGE_TYPE.list
    );

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicianTeams,
      params.technicianTeamId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicianTeams,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.technicianTeams,
      params.technicianTeamId,
      PAGE_TYPE.edit
    );
}

class OrderCustomFieldRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.parent.getEditBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'fieldName'> = (
    p,
    pm,
    t,
    n
  ) => {
    return this.getAddBc(p, pm, t, n).concat([{ name: n.fieldName, href: this.getInfoPath(p) }]);
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderCustomFields,
      PAGE_TYPE.list
    );

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderCustomFields,
      params.customFieldId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderCustomFields,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderCustomFields,
      params.customFieldId,
      PAGE_TYPE.edit
    );
}

class NotificationSettingsRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: EnterpriseSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
    const breadcrumbs = this.parent.getEditBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'fieldName'> = (
    p,
    pm,
    t,
    n
  ) => {
    return this.getAddBc(p, pm, t, n).concat([{ name: n.fieldName, href: this.getInfoPath(p) }]);
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderCustomFields,
      PAGE_TYPE.list
    );

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderCustomFields,
      params.customFieldId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderCustomFields,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterprise.orderCustomFields,
      params.customFieldId,
      PAGE_TYPE.edit
    );

  getNotificationsInfoBc = this.getInstanceBc;
  getNotificationsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.user.notifications, PAGE_TYPE.info);

  getNotificationsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'userName'> = (
    p,
    pm,
    t,
    n
  ) => {
    return this.getAddBc(p, pm, t, n).concat([{ name: n.userName, href: this.getNotificationsInfoPath(p) }]);
  };

  getNotificationsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) => {
    return this.getDBPath(
      PATH_PARAM_DASHBOARD.enterprise.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.user.notifications,
      PAGE_TYPE.edit
    );
  };
}

// class PlatformOperatorRoutes extends FrontendRoutesBase implements IFrontendRoutes {
//   constructor(private parent: EnterpriseSettingsRoutes) {
//     super();
//   }

//   getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName'> = (p, pm, t, n) => {
//     const breadcrumbs = this.parent.getEditBc(p, pm, t, n);
//     this.setLastBcLink(breadcrumbs, this.getListPath(p));
//     return breadcrumbs;
//   };

//   getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'fieldName'> = (
//     p,
//     pm,
//     t,
//     n
//   ) => {
//     return this.getAddBc(p, pm, t, n).concat([{ name: n.fieldName, href: this.getInfoPath(p) }]);
//   };

//   getListBc = this.parent.getInstanceBc;
//   getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
//     this.getDBPath(
//       PATH_PARAM_DASHBOARD.enterprise.root,
//       params.enterpriseId,
//       PATH_PARAM_DASHBOARD.enterprise.platformOperator,
//       PAGE_TYPE.list
//     );

//   getInfoBc = this.getInstanceBc;
//   getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
//     this.getDBPath(
//       PATH_PARAM_DASHBOARD.enterprise.root,
//       params.enterpriseId,
//       PATH_PARAM_DASHBOARD.enterprise.platformOperator,
//       params.operatorId,
//       PAGE_TYPE.info
//     );

//   getAddBc = this.getInstanceBc;
//   getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
//     this.getDBPath(
//       PATH_PARAM_DASHBOARD.enterprise.root,
//       params.enterpriseId,
//       PATH_PARAM_DASHBOARD.enterprise.platformOperator,
//       PAGE_TYPE.new,
//       PAGE_TYPE.add
//     );

//   getEditBc = this.getInstanceChildBc;
//   getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
//     this.getDBPath(
//       PATH_PARAM_DASHBOARD.enterprise.root,
//       params.enterpriseId,
//       PATH_PARAM_DASHBOARD.enterprise.platformOperator,
//       params.operatorId,
//       PAGE_TYPE.edit
//     );

//   getPlatformOperatorInfoBc = this.getInstanceBc;
//   getPlatformOperatorInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) =>
//     this.getDBPath(PATH_PARAM_DASHBOARD.enterprise.root, params.enterpriseId, PATH_PARAM_DASHBOARD.user.platformOperator, PAGE_TYPE.info);

//   getPlatformOperatorEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterprise, 'enterpriseName' | 'userName'> =
//     (p, pm, t, n) => {
//       return this.getAddBc(p, pm, t, n).concat([{ name: n.userName, href: this.getPlatformOperatorInfoPath(p) }]);
//     };

//   getPlatformOperatorEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterprise> = (params) => {
//     return this.getDBPath(
//       PATH_PARAM_DASHBOARD.enterprise.root,
//       params.enterpriseId,
//       PATH_PARAM_DASHBOARD.user.platformOperator,
//       PAGE_TYPE.edit
//     );
//   };
// }
